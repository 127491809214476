<template>
  <div class="content">
    <div class="headerTip">
      需求名称-<span style="color: #7c71e1">{{ this.dataList.demandName }}</span>
    </div>
    <div class="topHeader">
      <div class="userImg">
        <img class="img" :src="this.dataList.headPortrait" />
      </div>
      <div class="userCard">
        <div class="userCard_left">
          <div class="line_one">
            <div class="black_one">{{ this.dataList.userName }}</div>
            <div class="icon_man">
              <img
                src="../../../../../static/images/jr-icon-female.png"
                v-if="this.dataList.gender == 1"
              />
              <img
                src="../../../../../static/images/jr-icon-man.png"
                v-if="this.dataList.gender == 0"
              />
            </div>
          </div>
          <div class="line_two">
            <div>{{ this.dataList.mobile }}</div>
            <div>{{ this.dataList.ipCity }}</div>
            <div>{{ this.dataList.institution }}</div>
          </div>
        </div>
        <div class="userCard_right">
          <div class="VIPImg">
            <img
              src="../../../../../static/images/VIP.png"
              class="userCard_img"
              v-if="dataList.whetherMember == 1"
            />
          </div>
          <div class="deadline" v-if="this.dataList.whetherMember == 1">
            {{ dataList.deadline }}到期
          </div>
          <img
            src="../../../../../static/images/user.png"
            class="userCard_img"
            v-if="this.dataList.whetherMember == 0"
          />
        </div>
      </div>
    </div>
    <div class="info">
      <el-row>
        <el-col :span="3">
          <div class="label">角色:</div>
        </el-col>
        <el-col :span="12">
          {{ this.dataList.demandRole }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="label">需求:</div>
        </el-col>
        <el-col :span="12">
          <div style="display: flex">
            <div
              v-for="(item, index) in this.dataList.requirement"
              :key="index"
              style="margin: 0px 5px"
            >
              {{ item }}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="label">项目阶段:</div>
        </el-col>
        <el-col :span="12">
          {{ this.dataList.projectPhase }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="label">相关行业:</div>
        </el-col>
        <el-col :span="15">
          <div class="tip_two">
            <div v-for="(item, index) in this.dataList.correctIndustry" :key="index">
              {{ item }}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="label">信息有效期:</div>
        </el-col>
        <el-col :span="12">
          {{ this.dataList.messageValidity }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="label">预算状况:</div>
        </el-col>
        <el-col :span="12">
          <!-- <div>{{this.dataList.highLine}}~{{this.dataList.bottomLine}}</div> -->
          <div v-if="this.dataList.budgetSituation == '' || this.dataList.budgetSituation == null">
            不明确
          </div>
          <div v-else>
            {{ this.dataList.budgetSituation.split(',')[0] }}万~{{
              this.dataList.budgetSituation.split(',')[1]
            }}万
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="label">需求标签:</div>
        </el-col>
        <el-col :span="12">
          <div>{{ this.dataList.demandLabel }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="label">详细描述:</div>
        </el-col>
        <el-col :span="12">
          <div class="PI">
            <div class="text_area">{{ this.dataList.demandDescribe }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="label">附加信息:</div>
        </el-col>
        <el-col :span="12">
          <div v-for="(item, index) in this.dataList.fileName" :key="index">
            <div class="supplyFile">{{ item }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="btn_area">
      <div class="btn" v-if="this.dataList.linkStatus == 1">已联系</div>
      <div class="btn not" v-if="this.dataList.linkStatus == 0" @click="updatedDemand(dataList)">
        未联系
      </div>
    </div>
  </div>
</template>
<script>
import { getDemandDetail, updatedDemand } from '@/api/supplyInfo.js'
export default {
  components: {},
  data() {
    return {
      dataList: [],
      queryInfo: {}
    }
  },
  mounted() {
    this.getDetail()
    // console.log(this.$route.query,"query");
  },
  methods: {
    async getDetail() {
      const { data: res } = await getDemandDetail({ id: this.$route.query.id })
      // console.log(res)
      this.dataList = res.data
      if (this.dataList.deadline) this.dataList.deadline = this.dataList.deadline.split(' ')[0]
      if (this.dataList.highLine)
        this.dataList.highLine = this.dataList.budgetSituation.split(',')[0]
      if (this.dataList.bottomLine)
        this.dataList.bottomLine = this.dataList.budgetSituation.split(',')[1]
    },
    async updatedDemand(list) {
      // console.log(list,'list');
      this.queryInfo.id = list.id
      list.linkStatus == 1 ? (this.queryInfo.linkStatus = 0) : (this.queryInfo.linkStatus = 1)
      this.$confirm(`确认更新联系状态？`, {
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await updatedDemand(this.queryInfo)
          if (res.resultCode === 200) {
            this.$message.success(`更新成功`)
            this.getDetail()
          } else {
            this.$message.error('更新失败，请重试。')
          }
        })
        .catch(() => {})
    }
  }
}
</script> 
<style scoped lang="less">
.supplyFile {
  margin-bottom: 10px;
  color: #428dfe;
  cursor: pointer;
}
.el-row {
  margin-bottom: 16px;
}
.content {
  background: #ffffff;
  margin: 16px 0px 0px 25px;
  overflow: hidden;
  height: auto !important;
  .headerTip {
    text-indent: 1em;
    margin: 20px 0px 10px 10px;
    height: 40px;
    color: #838383;
    font-size: 22px;
    border-bottom: 1px solid #efefef;
  }
  .topHeader {
    margin: 25px 0px 0px 27px;
    display: flex;
    .userImg {
      width: 160px;
      height: 160px;
      border-radius: 4px;
      .img {
        width: 100%;
        height: 100%;
      }
    }
    .userCard {
      margin-left: 25px;
      width: 480px;
      height: 160px;
      background: linear-gradient(91.34deg, #d6d6fe 1.98%, #e3d6ff 57.14%, #abc3fd 98.77%);
      border-radius: 12px;
      .userCard_left {
        float: left;
        margin: 15px 0px 15px 30px;
        .line_one {
          display: flex;
          margin-bottom: 30px;
          .black_one {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            margin-right: 15px;
          }
          .icon_man {
            width: 16px;
            height: 14px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .line_two {
          margin-top: 6px;
          div {
            margin-bottom: 5px;
            color: #333333;
          }
        }
        .line_three {
          display: flex;
          flex-wrap: wrap;
          width: 240px;
          margin-top: 10px;
          .right_one {
            margin-left: 90px;
          }
          div {
            margin-top: 15px;
            color: #7c71e1;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
      .userCard_right {
        float: right;
        width: 142.8px;
        height: 119px;
        margin: 21px 21px 21px 0px;
        .VIPImg {
          width: 125px;
          height: 105px;
        }
        .userCard_img {
          width: 100%;
          height: 100%;
        }
        .deadline {
          width: 125px;
          margin-top: 10px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #9388f9;
        }
      }
    }
  }
  .info {
    margin: 20px 0px 0px 35px;
    width: 60%;
    .label {
      width: 90px;
      text-align: right;
    }
    .tip_two {
      display: flex;
      div {
        margin-right: 10px;
        padding: 5px 7px;
        font-size: 12px;
        line-height: 17px;
        color: #7067f2;
        background: #7067f21a;
      }
    }
    .PI {
      background: #fbf9f9;
      width: 690px;
      height: 143px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 20px;
      color: #151515;
      overflow: hidden;
      border: 1px solid #838383;
      .text_area {
        margin: 20px 20px 0px 20px;
      }
    }
  }
  .btn_area {
    margin: 40px 60px;
    width: 100%;
    display: flex;
    .btn {
      text-align: center;
      line-height: 40px;
      width: 136px;
      height: 40px;
      background: #c4c4c4;
      color: #333333;
      border-radius: 4px;
    }
    .not {
      background: #7c71e1;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>
